import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PagedCollection } from '../item-pager/paged-collection';
import { AdminOperationResult } from '../shared/dtos/admin-operation';
import { RequestService } from '../shared/request.service';
import { ToastService } from '../toasts/toast.service';
import { AssignAdminLicenceToUser } from './dtos/assign-licence-to-user';
import { LicenceContentAccessDetails } from './dtos/licence-content-access-details';
import { LicenceDetails } from './dtos/licence-details';
import { LicenceDetailsData } from './dtos/licence-details-data';
import { LicenceGiftTokenSummary } from './dtos/licence-gift-token-summary';
import { LicenceSearchQuery } from './dtos/licence-search-query';
import { LicenceSummaryData } from './dtos/licence-summary-data';
import { LicenceSummaryItem } from './dtos/licence-summary-item';

@Injectable({
  providedIn: 'root',
})
export class LicenceService {
  constructor(
    private requestService: RequestService,
    private toastService: ToastService
  ) {}

  getById(id: string): Observable<LicenceDetails> {
    return this.requestService
      .adminQuery<LicenceDetailsData>('GetLicenceById', { id })
      .pipe(map((data) => new LicenceDetails(data)));
  }

  getByOrderId(id: string): Observable<LicenceSummaryItem[]> {
    return this.requestService
      .adminQuery<LicenceSummaryData[]>('GetLicencesForOrder', { id })
      .pipe(map((data) => data.map((d) => new LicenceSummaryItem(d))));
  }

  createGiftToken(
    organisationId: string,
    licenceIds: string[]
  ): Observable<LicenceGiftTokenSummary> {
    return this.requestService
      .adminCommand<LicenceGiftTokenSummary>('CreateGiftToken', {
        organisationId,
        licenceIds,
      })
      .pipe(
        map((data) => {
          return {
            id: data.id,
            code: data.code,
            licences: data.licences.map((l) => new LicenceSummaryItem(l)),
            createdBy: data.createdBy,
            createdDate: data.createdDate,
            redeemedDate: data.redeemedDate,
            redeemedBy: data.redeemedBy,
          };
        })
      );
  }

  redeemGiftToken(organisationId: string, giftCode: string): Observable<any> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AdminRedeemLicenceGiftCode',
      { organisationId, giftCode }
    );
  }

  getGiftTokenById(id: string) {
    return this.requestService
      .adminQuery<LicenceGiftTokenSummary>('GetGiftTokenById', { id })
      .pipe(
        map((data) => {
          return {
            id: data.id,
            code: data.code,
            licences: data.licences.map((l) => new LicenceSummaryItem(l)),
            createdBy: data.createdBy,
            createdDate: data.createdDate,
            redeemedDate: data.redeemedDate,
            redeemedBy: data.redeemedBy,
          };
        })
      );
  }

  deleteGiftToken(id: string) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'DeleteGiftToken',
      { id }
    );
  }

  addNewLicences(
    organisationId: string,
    startDate: Date,
    endDate: Date,
    quantity: number,
    createVouchers: boolean,
    isActive: boolean
  ) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AdminCreateLicences',
      {
        organisationId,
        startDate,
        endDate,
        quantity,
        createVouchers,
        isActive,
      }
    );
  }

  deleteLicence(id: string) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'DeleteLicence',
      { id }
    );
  }
  unassignLicence(id: string) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UnassignAdminLicence',
      { id }
    );
  }
  assignLicenceToUser(request: AssignAdminLicenceToUser) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'AssignAdminLicenceToUser',
      request
    );
  }
  manageExpiryDate(id: string, name: string, email: string, expiryDate: Date) {
    return this.requestService
      .adminCommand<AdminOperationResult>('ManageLicenceExpiryDate', {
        id,
        expiryDate,
      })
      .pipe(
        tap((_) => {
          this.toastService.showSuccess(
            `${name}'s (${email}) expiry date updated`
          );
        })
      );
  }
  updateCutoffDate(ids: string[], cutOffDate: Date) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'UpdateCutoffDate',
      {
        ids,
        cutOffDate,
      }
    );
  }
  transferLicences(organisationId: string, licenceIds: string[]) {
    return this.requestService.adminCommand<AdminOperationResult>(
      'TransferLicences',
      { organisationId, licenceIds }
    );
  }

  getLicencesWithDifferentDates(
    page: number
  ): Observable<PagedCollection<LicenceContentAccessDetails>> {
    return this.requestService
      .adminQuery<PagedCollection<LicenceContentAccessDetails>>(
        'GetLicencesWithContentAccessWithDifferentDates',
        { page }
      )
      .pipe(map((data) => PagedCollection.parseAndMapData(data, (x) => x)));
  }

  getActiveLicencesForOrganisation(organisationId: string) {
    return this.requestService.adminQuery<LicenceSummaryItem[]>(
      'GetActiveLicencesForOrganisation',
      { organisationId }
    );
  }

  getLicencesNotSynced() {
    return this.requestService.adminQuery<LicenceSummaryItem[]>(
      'GetLicencesNotSynced',
      {}
    );
  }

  setNotSynced(licenceId: string): Observable<AdminOperationResult> {
    return this.requestService.adminCommand<AdminOperationResult>(
      'SetLicenceAsNotSynced',
      { orderId: licenceId }
    );
  }

  // getUsageReport(
  //   organisationId: any,
  //   startDate: any,
  //   endDate: any
  // ): Observable<any> {
  //   return this.requestService.adminDownload(
  //     'AdminGetOrganisationNBPlusUsage',
  //     { organisationId, startDate, endDate }
  //   );
  // }

  public search(request: LicenceSearchQuery) {
    return this.requestService
      .adminQuery<PagedCollection<LicenceSummaryItem>>(
        'SearchLicences',
        request
      )
      .pipe(
        map((data) =>
          PagedCollection.parseAndMapData(
            data,
            (x) => new LicenceSummaryItem(x)
          )
        )
      );
  }
}
