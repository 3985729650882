import { UserInfo } from 'src/app/orders/dtos/user-info';
import { OrganisationDetailSummary } from 'src/app/organisations/dtos/organisation-details-data';
import { VoucherDetails } from 'src/app/vouchers/dtos/voucher-details';

import { LicenceAssignmentDetails } from './licence-assignment-details';
import { LicenceDetailsData } from './licence-details-data';
import { LicenceTermDetails } from './licence-term-details';

export class LicenceDetails {
  constructor(data: LicenceDetailsData) {
    this.id = data.id;
    this.organisation = data.organisation;
    this.licenceNumber = data.licenceNumber;
    this.validFrom = data.validFrom;
    this.validTo = data.validTo;
    this.currentAssignment = data.currentAssignment;
    this.status = data.status;
    this.assignments = data.assignments;
    this.terms = data.terms;
    this.vouchers = data.vouchers;
  }

  readonly id: string;
  readonly organisation: OrganisationDetailSummary;
  readonly licenceNumber: string;
  readonly productName: string;
  readonly validFrom: Date;
  readonly validTo: Date;
  readonly currentAssignment: UserInfo;
  readonly status: string;
  readonly assignments: LicenceAssignmentDetails[];
  readonly terms: LicenceTermDetails[];
  readonly vouchers: VoucherDetails[];
}
